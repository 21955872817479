<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="16">
        <!-- 搜索与添加 -->
        <el-input
            :placeholder="$t('tips.images.query')"
            class="input-with-select"
            v-model.trim="query"
            @clear="clearQuery('search')"
            clearable>
          <el-select
              slot="prepend"
              :placeholder="$t('tips.images.folder')"
              @change="selectFolder"
              v-model="thisFolder">
            <el-option
                v-for="item in folderAll"
                :key="'folder' + item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
      </el-col>
      <el-col :span="24" class="mgb20">
        <el-checkbox v-if="multiple" v-model="selectall" :disabled="all" border size="mini" @change="selectAll" style="margin-right:10px">{{$t('button.select_all')}}</el-checkbox>
        <el-button v-if="multiple" type="danger" :disabled="disabledDel" @click="removeImages" size="mini">{{$t('button.del')}}</el-button>
        <el-button type="success" icon="el-icon-refresh" @click="refresh" size="mini" :disabled="refreshDis">{{$t('button.refresh')}}</el-button>
        <el-button type="primary" @click="uploadImage" size="mini">{{$t('button.upload_image')}}</el-button>
        <el-button type="primary" icon="el-icon-folder-add" @click="addFolderDialogVisible = true" size="mini">{{$t('button.new_folder')}}</el-button>
      </el-col>
    </el-row>

    <transition name="el-fade-in">
      <el-row :gutter="20" v-if="editFolderData.id">
        <el-col :span="12">
          <el-page-header class="mb20" @back="clearQuery" :content="editFolderData.name + ' (' + total+ ')'"></el-page-header>
        </el-col>
        <el-col :span="12">
          <el-button-group class="right">
            <el-button type="primary" icon="el-icon-edit" @click="editFolderDialogVisible = true" size="mini"></el-button>
            <el-button type="danger" icon="el-icon-delete" @click="removeFolder(editFolderData.id)" size="mini"></el-button>
          </el-button-group>
        </el-col>
      </el-row>
    </transition>

    <transition name="el-fade-in">
      <el-row :gutter="20" v-loading="loading">
        <el-col :span="6" v-for="(item, index) in srcList" :key="index" class="images-list line">
          <el-image
              fit="cover"
              lazy
              :src="item.path"
              @click="() => {multiple ? '' : getSelect([item])}"
              :preview-src-list="multiple ? srcAll : []">
          </el-image>
          <el-checkbox v-if="multiple" v-model="item.checkbox" @change="changeCheckbox"></el-checkbox>
          <div class="images-setting">
            <div class="images-button-group">
              <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="getImg(item)"></el-button>
              <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeImg(item.id)"></el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </transition>

    <el-empty v-if="srcList === undefined || srcList.length == 0">
      <el-button type="text" size="mini" icon="el-icon-upload" @click="uploadImage">{{$t('button.upload_image')}}</el-button>
      <el-button type="text" size="mini" icon="el-icon-refresh" @click="refresh" :disabled="refreshDis">{{$t('button.refresh')}}</el-button>
    </el-empty>

    <el-pagination
        :hide-on-single-page="onePage"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="pageSizes"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>

    <el-link class="mt20" :underline="false">({{imgSize}} / {{bytesToSize(totalSize)}})</el-link>
    <el-progress :percentage="percentage"></el-progress>

    <el-row>
      <el-col>
        <div class="mt20 right">
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="close">{{$t('button.cancel')}}</el-button>
          <el-button type="primary" icon="el-icon-edit" size="mini" @click="getSelect">{{$t('button.ok')}}</el-button>
        </div>
      </el-col>
    </el-row>

    <!-- 编辑图片信息 -->
    <el-dialog
        :title="$t('button.edit')"
        :visible.sync="getImgDialogVisible"
        @close="dialogClose('editImage')"
        :append-to-body="select">
      <create-form
          ref="editImage"
          size='medium'
          :createData="editData"
          :createForm="editForm"
          :rule="editRules">
      </create-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="getImgDialogVisible = false">{{$t('button.cancel')}}</el-button>
        <el-button type="primary" @click="editImg">{{$t('button.ok')}}</el-button>
      </span>
    </el-dialog>

    <!-- 上传图片 -->
    <el-dialog
        :title="$t('button.upload_image')"
        :visible.sync="uploadImageDialogVisible"
        :before-close="clearFiles"
        width="30%"
        :append-to-body="select">
      <el-upload
          ref="upload"
          class="upload-images"
          drag
          :action="uploadURL"
          :data="uploadData"
          :on-success="handleSuccess"
          :on-error="handleError"
          :headers="headerObj"
          :on-exceed="exceedImg"
          :limit="maxUpload"
          :before-upload="beforeUpload"
          accept=".jpg,.JPG,.png,.PNG"
          multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">{{$t('tips.images.upload_text')}}</div>
        <div class="el-upload__tip" slot="tip">{{$t('tips.images.upload_tip') + '，' + $t('tips.images.isLt5K')}}</div>
      </el-upload>
    </el-dialog>

    <!-- 新建文件夹 -->
    <el-dialog
        :title="$t('button.new_folder')"
        :visible.sync="addFolderDialogVisible"
        @close="dialogClose('addFolder')"
        :append-to-body="select">
      <create-form
          ref="addFolder"
          size='medium'
          :createData="addFolderData"
          :createForm="folderForm"
          :rule="fromRules">
      </create-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addFolderDialogVisible = false">{{$t('button.cancel')}}</el-button>
        <el-button type="primary" @click="addFolder">{{$t('button.ok')}}</el-button>
      </span>
    </el-dialog>

    <!-- 编辑文件夹 -->
    <el-dialog
        :title="$t('button.edit_folder')"
        :visible.sync="editFolderDialogVisible"
        @close="dialogClose('editFolder')"
        :append-to-body="select">
      <create-form
          ref="editFolder"
          size='medium'
          :createData="editFolderData"
          :createForm="folderForm"
          :rule="fromRules">
      </create-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editFolderDialogVisible = false">{{$t('button.cancel')}}</el-button>
        <el-button type="primary" @click="editFolder">{{$t('button.ok')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash' // 深拷贝
import config from '@/config'
import { getToken } from '@/utils/auth'
import { getImages, editImages, removeImages, sizeImages, addFolder, getFolder, editFolder, removeFolders } from '@/api/console/admin'
import CreateForm from '@/components/Form'

export default {
  name: 'select-image',
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    select: {
      type: Boolean,
      default: true
    },
    active: {
      type: Boolean,
      default: true
    },
    attrs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      url: config.baseUrl.dev,
      loading: true,
      query: '',
      // 图片显示列表
      srcList: [],
      // 图片预览列表
      srcAll: [],
      getImgDialogVisible: false,
      // 编辑图片信息
      editData: [],
      imgSize: '',
      storage: 0,
      // 50M 存储空间
      totalSize: 5242880000,
      percentage: 0,
      // 分页
      total: 0,           // 总条数
      pageSize: config.default.pageLimit,        // 分页大小
      pageSizes: config.default.pageSizes,
      currentPage: 1,     // 当前页
      onePage: false,
      // 上传图片
      uploadImageDialogVisible: false,
      // 上传图片路径
      uploadURL: config.baseUrl.dev + '/api/Images/upload',
      uploadData: {},
      headerObj: {
        'api-auth': getToken(),
        'access-token': config.AccessToken
      },
      // 限制单次上传数
      maxUpload: 5,
      // 文件夹列表
      folderAll: [],
      propsFolder: {
        value: 'id',
        label: 'name',
      },
      // 选中项
      checkboxAll: [],
      refreshDis: false,
      // 全选
      selectall: false,

      // 新建文件夹
      addFolderDialogVisible: false,
      addFolderData:{
        name: '',
        desc: ''
      },
      // 编辑文件夹
      thisFolder: '',
      editFolderDialogVisible: false,
      editFolderData: {},
    }
  },
  components: {
    CreateForm
  },
  computed: {
    folderForm() {
      return [
        {
          type: 'Input',
          label: this.$t('form.name'),
          prop: 'name',
          placeholder: this.$t('tips.enter')
        },
        {
          type: 'textarea',
          label: this.$t('form.desc'),
          prop: 'desc',
          row: '5',
          max: 30,
          placeholder: this.$t('tips.enter')
        }
      ]
    },
    editForm() {
      return [
        {
          type: 'Input',
          label: this.$t('form.name'),
          prop: 'title',
          placeholder: this.$t('tips.enter')
        },
        {
          type: 'Cascader',
          label: this.$t('tips.images.folder'),
          prop: 'folder',
          options: this.folderAll,
          props: this.propsFolder,
          placeholder: this.$t('tips.select')
        },
        {
          type: 'textarea',
          label: this.$t('form.desc'),
          prop: 'desc',
          row: '5',
          max: 30,
          placeholder: this.$t('tips.enter')
        },
        {
          type: 'Text',
          label: this.$t('form.status'),
          prop: 'status'
        },
        {
          type: 'Text',
          label: this.$t('form.images.edit.size'),
          prop: 'size'
        },
        {
          type: 'Text',
          label: this.$t('form.create_time'),
          prop: 'create_time'
        },
        {
          type: 'Text',
          label: this.$t('form.update_time'),
          prop: 'update_time'
        },
        {
          type: 'Text',
          label: this.$t('form.images.edit.path'),
          prop: 'path'
        }
      ]
    },
    // 表单验证
    fromRules() {
      const rules = {
        name: [
          { required: true, message: this.$t('validate.empty'), trigger: 'blur' },
          { min: 2, max: 10, message: this.$t('validate.max_length') + '2~10', trigger: 'blur' }
        ],
      }
      return rules
    },
    // 编辑图片表单验证
    editRules() {
      const rules = {
        title: [
          { required: true, message: this.$t('validate.empty'), trigger: 'blur' },
          // { min: 2, max: 10, message: this.$t('validate.max_length') + '2~10', trigger: 'blur' }
        ]
      }
      return rules
    },
    // 禁用删除
    disabledDel() {
      return this.checkboxAll.length > 0 ? false : true
    },
    // 全选状态
    all(){
      return this.srcList.length>0 ? false : true
    }
  },
  mounted() {
    this.getImgList(),
        this.size(),
        this.getFolder()
  },
  methods: {
    // 关闭对话框
    dialogClose(ref) {
      this.$nextTick(() => {
        this.$refs[ref].clearValidate()
      })
    },
    // 搜索图片
    search() {
      if(this.query) {
        this.currentPage = 1
        return this.getImgList()
      }
      return this.$message.warning(this.$t('tips.search'))
    },
    // 刷新
    refresh() {
      // this.currentPage = 1
      // this.pageSize = config.default.pageLimit
      // this.thisFolder = ''
      // this.editFolderData = {}
      this.query = ''
      this.refreshDis = true
      this.getImgList()
      this.$message.success(this.$t('tips.success'))
      setTimeout(() => {
        this.refreshDis = false
      }, 5000)
    },
    getImgList() {
      this.loading = true
      let data = {
        currentPage: this.currentPage,
        pagesize: this.pageSize,
        query: this.query,
        folder: this.editFolderData.id
      }
      getImages(data).then(res => {
        this.selectall = false  // 清除全选
        this.checkboxAll = []   // 清空选择项
        let list = res.data.data
        // 初始化预览图列表
        this.srcAll = []
        list.data.forEach(element => {
          element.path = this.url + element.path
          element.checkbox = false
          this.srcAll.push(element.path)
        });
        this.srcList = list.data
        this.total = list.total
        if(this.total <= this.pageSize) {
          this.onePage = true
        }
        this.loading = false
      })
    },
    // 获取图片信息
    getImg(data){
      this.getImgDialogVisible = true
      // 深拷贝对象
      this.editData = JSON.parse(JSON.stringify(data))
      this.editData.size = Math.round(parseInt(this.editData.size) / 1024) + 'KB'
    },
    // 修改图片信息
    async editImg() {
      let valid = await this.$refs.editImage.validate()
      if( !valid ) return;
      let editData = this.editData
      editData.folder  = editData.folder[0] ? editData.folder[0] : 0
      editImages(editData).then(res => {
        if(res.data.code === 1) {
          this.$message.success(this.$t('tips.success'))
          this.getImgDialogVisible = false
          this.getImgList()
        }
      })
    },
    // 删除图片
    removeImg(id) {
      this.$confirm(this.$t('tips.del'), this.$t('tip'), {
        type: 'warning'
      }).then(() => {
        removeImages(id).then(res => {
          if(res.data.code === 1) {
            this.$message({
              type: 'success',
              message: this.$t('tips.success')
            });
            this.getImgList()
          }
        })
      })
    },
    // 选择分页大小
    handleSizeChange(pagesize) {
      this.pageSize = pagesize
      this.getImgList()
    },
    // 选择当前页
    handleCurrentChange(page) {
      this.currentPage = page
      this.getImgList()
    },
    // 图片占用空间
    size() {
      sizeImages().then(res => {
        this.storage = res.data.data.size
        this.imgSize = this.bytesToSize(res.data.data.size)
        let percentage = (this.storage / this.totalSize).toFixed(2)
        if(Math.round(percentage * 100) === 0) {
          return this.percentage = 0.1
        }
        this.percentage = Math.round(percentage * 100)
      })
    },
    // 格式化数据
    bytesToSize(bytes) {
      if (bytes === 0) return '0 B';
      var k = 1024, // or 1000
          sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
          i = Math.floor(Math.log(bytes) / Math.log(k));
      return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
    },
    uploadImage() {
      this.uploadImageDialogVisible = true
    },
    clearFiles() {
      this.$refs.upload.clearFiles();
      this.uploadImageDialogVisible = false
    },
    // 监听上传图片前
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isPG = (isJPG || isPNG)                   // 限制图片格式为jpg / png
      const isLt2M = file.size / 1024 / 1024 < 0.5;   // 限制图片大小
      /**
       *  限制上传图片大小
       const isSize = new Promise(function(resolve,reject) {
                    let width = 600
                    let height = 400
                    let _URL = window.URL || window.webkitURL
                    let img = new Image()
                    img.onload = function() {
                        let valid = img.width >= width && img.height >= height
                        valid ? resolve() : reject();
                    }
                    img.src = _URL.createObjectURL(file)
                }).then(() => {
                    return file;
                },()=>{
                    this.$message.error('上传图片像素要大于600*400!');
                    return promise.reject();
                })
       */
      if (!isPG) {
        this.$message.error(this.$t('tips.images.upload_tip'));
      }
      if (!isLt2M) {
        this.$message.error(this.$t('tips.images.isLt5K'));
      }
      this.$set(this.uploadData,'folder', this.thisFolder)
      return isPG && isLt2M // && isSize
    },
    // 监听图片上传成功的事件
    handleSuccess(response, file, fileList) {
      if(response.code !== 1) {
        return this.$message.error(response.msg || '上传失败')
      }
      return this.$message.success('上传成功')
    },
    // 上传失败
    handleError(err, file, fileList) {
      debugger
    },
    // 超出上传限制
    exceedImg(files, fileList) {
      if(fileList.length < this.maxUpload) {
        return this.$message.error(this.$t('tips.images.limit'))
      }
      return this.$message.error(this.$t('tips.images.limit_tip'))
    },
    // 获取文件夹
    getFolder() {
      getFolder().then(res => {
        this.folderAll = res.data.data.list
      })
    },
    // 新建文件夹
    async addFolder() {
      let valid = await this.$refs.addFolder.validate()
      if( !valid ) return;

      addFolder(this.addFolderData).then(res => {
        this.addFolderDialogVisible = false
        this.$message.success(res.data.msg)
        this.getFolder()
      })
    },
    // 编辑文件夹
    async editFolder() {
      let valid = await this.$refs.editFolder.validate()
      if( !valid ) return;
      const data = this.editFolderData
      editFolder(data).then(res => {
        this.editFolderDialogVisible = false
        this.$message.success(res.data.msg)
        this.getFolder()
      })
    },
    // 删除文件夹
    removeFolder(id) {
      this.$confirm(this.$t('tips.del_folder'), this.$t('tip'), {
        type: 'warning'
      }).then(() => {
        removeFolders(id).then(res => {
          this.$message.success(this.$t('tips.success'))
          this.clearQuery()
          this.getFolder()
        })
      })
    },
    // 选择的当前文件夹
    selectFolder(value) {
      let obj = this.folderAll.find( item =>{
        return item.id === value;
      });
      this.editFolderData = _.cloneDeep(obj)
      if(value) {
        this.currentPage = 1    // page初始化
        this.getImgList()
      }
    },
    // 清空搜索
    clearQuery(search = '') {
      if(search === 'search') {
        this.query = ''
      } else{
        this.thisFolder = ''
        this.editFolderData = {}
      }
      this.getImgList()
    },
    // 选中
    changeCheckbox() {
      let data = this.srcList.filter(x => x.checkbox === true),
          checkbox = []
      data.forEach((v, i) => {
        checkbox.push(v.id)
      })
      /*if(!this.multiple) {
          checkbox = checkbox.slice(-1)
          data.forEach((v, i) => {
              if(v.id !== checkbox[0]) {
                  v.checkbox = false
              }
          })
      }*/

      this.checkboxAll = checkbox
    },
    // 批量删除
    removeImages() {
      let id = this.checkboxAll.join(',')
      this.removeImg(id)
    },
    // 全选
    selectAll() {
      this.srcList.forEach(element => {
        element.checkbox = this.selectall ? true : false
      });
      this.changeCheckbox()
    },
    getSelect(data = '') {
      if(this.multiple) {
        data = this.srcList.filter(x => x.checkbox === true)
      }

      if(data && data.length > 0) {
        let attrs = this.attrs;
        let list = [];
        if(attrs && attrs.length > 0) {
          data.forEach(e => {
            let image = {}
            attrs.forEach(i => {
              image[i] = e[i]
            })
            list.push(image)
          })
        } else {
          data.forEach(e => {
            list.push(e.path)
          })
        }

        return this.$emit('select', this.multiple ? list : list[0] );
      }

      this.$message.warning(this.$t('tips.not_selected'))
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style lang="less" scoped>
.images-list{
  position: relative;
  margin-bottom: 10px;
}
.images-list:hover .images-setting{
  opacity: 1;
}
.images-list .el-image{
  width: 100%;
  height: 100px;
  display: block;
  cursor: pointer;
}
.images-list .el-checkbox{
  position: absolute;
  right: 10px;
  top: 0;
  padding: 6px;
}
.images-setting{
  position: absolute;
  top: 60%;
  bottom: 0;
  left: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-transition: .5s ease all;
  -moz-transition: .5s ease all;
  -o-transition: .5s ease all;
  transition: .5s ease all;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.images-setting .images-button-group{
  position: absolute;
  width: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.img-preview{
  margin-top: 20px;
  width: 30%;
  border-radius: 5px;
}
.upload-images{
  width: 100%;
  max-width: 360px;
  margin: auto;
}
.el-card{
  overflow: visible;
}
</style>
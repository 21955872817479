import axios from '@/utils/request';

/**
 * 获取所有分类
 */
export const getCategory = ({page, size, query}) => {
  return axios.request({
    url: '/api/Category/index',
    method: 'get',
    params: {
      page: page,
      limit: size,
      query: query
    }
  })
}

/**
 * 添加分类
 */
export const addCategory = (addData) => {
    return axios.request({
      url: '/api/Category/add',
      method: 'post',
      data: addData
    })
}

/*
 * 修改分类信息
 */
export const editCategory = (editData) => {
  return axios.request({
    url: '/api/Category/edit',
    method: 'post',
    data: editData
  })
}

/*
 * 修改分类状态
 */
export const changeCategory = (id, status) => {
  return axios.request({
    url: '/api/Category/change',
    method: 'get',
    params: {
      id,
      status
    }
  })
}

/**
 * 删除分类
 */
export const removeCategory = (id) => {
  return axios.request({
    url: '/api/Category/remove',
    method: 'get',
    params: {
      id
    }
  })
}


/**
 * 获取所有图片
 */
export const getImages = ({currentPage, pagesize, query, folder}) => {
  return axios.request({
    url: '/api/Images/index',
    method: 'get',
    params: {
      page: currentPage,
      limit: pagesize,
      query: query,
      folder: folder
    }
  })
}

/**
 * 修改图片信息
 */
export const editImages = (editData) => {
  return axios.request({
    url: '/api/Images/edit',
    method: 'post',
    data: editData
  })
}

/**
 * 删除图片
 */
export const removeImages = (id) => {
  return axios.request({
    url: '/api/Images/remove',
    method: 'get',
    params: {
      id: id
    }
  })
}

/*
 * 图片占用空间
 */
export const sizeImages = () => {
  return axios.request({
    url: '/api/Images/size',
    method: 'get'
  })
}

/*
 * 新建文件夹
 */
export const addFolder = (folderData) => {
  return axios.request({
    url: '/api/Images/addFolder',
    method: 'post',
    data: folderData
  })
}

/*
 * 修改文件夹
 */
export const editFolder = (folderData) => {
  return axios.request({
    url: '/api/Images/editFolder',
    method: 'post',
    data: folderData
  })
}

/**
 * 
 * @param {*} id 
 */
export const removeFolders = (id) => {
  return axios.request({
    url: '/api/Images/removeFolder',
    method: 'get',
    params: {
      id
    }
  })
}

/*
 * 获取文件夹
 */
export const getFolder = (id) => {
  return axios.request({
    url: '/api/Images/getFolder',
    method: 'get'
  })
}

/**
 * 获取地区
 */
export const getRegion = (params) => {
  return axios.request({
    url: '/api/Region/index',
    method: 'get',
    params
  })
}

/**
 * 获取父级分类信息
 */
export const parentdata = (id) => {
  return axios.request({
    url: '/api/Region/getFid',
    method: 'get',
    params: {
      id
    }
  })
}


/**
 * 修改地区
 */
export const editRegion = (data) => {
  return axios.request({
    url: '/api/Region/edit',
    method: 'post',
    data
  })
}

/**
 * 改变状态
 */
export const changeRegion = (params) => {
  return axios.request({
    url: '/api/Region/change',
    method: 'get',
    params
  })
}

export const regionAll = (params) => {
  return axios.request({
    url: '/api/Region/all',
    method: 'get',
    params
  })
}
<template>
  <div>
    <el-form :size="size" :model="createData" :inline="inline" :label-width="labelWidth" :rules="rule" ref="createRef">
        <el-form-item v-for='item in createForm' :label="item.label" :key='item.prop' :prop="item.prop">
            <!-- 输入框 -->
            <el-input clearable v-if="item.type==='Input'" v-model="createData[item.prop]" :size="size" :placeholder="item.placeholder" :style="{width: item.width ? item.width:'auto'}"></el-input>
            
            <el-input clearable v-if="item.type==='textarea'" v-model="createData[item.prop]" type="textarea" :rows="item.row" :maxlength="item.max" :placeholder="item.placeholder" show-word-limit></el-input>
            <!-- 下拉框 -->
            <el-select clearable v-if="item.type==='Select'" :placeholder="item.placeholder" v-model="createData[item.prop]" :style="{width: item.width ? item.width:'auto'}" :size="size" @change="item.change(createData[item.prop])">
                <el-option v-for="op in item.options" :label="op.label" :value="op.value" :key="op.value"></el-option>
            </el-select>
            <!-- 级联选择器 -->
            <el-cascader clearable v-if="item.type==='Cascader'" v-model="createData[item.prop]" :options="item.options" :props="item.props"  @change="item.change && item.change(createData[item.prop])" :key="item.key" :placeholder="item.placeholder"></el-cascader>
            <!-- 单选 -->
            <el-radio-group v-if="item.type==='Radio'" v-model="createData[item.prop]" @change="item.change && item.change(createData[item.prop])">
                <el-radio v-for="ra in item.radios" :label="ra.value" :key="ra.value">{{ra.label}}</el-radio>
            </el-radio-group>
            <!-- 单选按钮 -->
            <el-radio-group v-if="item.type==='RadioButton'" v-model="createData[item.prop]" @change="item.change && item.change(createData[item.prop])">
                <el-radio-button v-for="ra in item.radios" :label="ra.value" :key="ra.value">{{ra.label}}</el-radio-button>
            </el-radio-group>
            <!-- 复选框 -->
            <el-checkbox-group v-if="item.type==='Checkbox'" :style="{width: item.width ? item.width:'auto'}" v-model="createData[item.prop]" >
                <el-checkbox v-for="ch in item.checkboxs" :label="ch.value" :key="ch.value">{{ch.label}}</el-checkbox>
            </el-checkbox-group>
            <!-- 日期 -->
            <el-date-picker v-if="item.type==='Date'" :placeholder="item.placeholder" v-model="createData[item.prop]"></el-date-picker>
            <!-- 时间 -->
            <el-time-select v-if="item.type==='Time'" v-model="createData[item.prop]" type=''></el-time-select>
            <!-- 日期时间 -->
            <el-date-picker v-if="item.type==='DateTime'" type='datetime' v-model="createData[item.prop]" :disabled="item.disable && item.disable(createData[item.prop])"></el-date-picker>
            <!-- 滑块 -->
            <!-- <el-slider v-if="item.type==='Slider'" v-model="createData[item.prop]"></el-slider> -->
            <!-- 开关 -->
            <el-switch v-if="item.type==='Switch'" v-model="createData[item.prop]" ></el-switch>
            <el-tag v-if="item.type==='Text'" type="warning" >{{createData[item.prop]}}</el-tag>

            <el-popover
                placement="top-start"
                width="200"
                trigger="hover" v-if="item.type==='Image'">
              <el-image :src="createData[item.prop]"></el-image>
              <el-input slot="reference" v-model="createData[item.prop]" size="small" clearable>
                <el-button slot="prepend" icon="el-icon-upload" @click="mediaUpload(item.prop)"></el-button>
              </el-input>
            </el-popover>
        </el-form-item>
        <el-form-item v-if='isHandle'>
            <el-button v-for='item in createHandle' :key="item.label" :type="item.type" :size="item.size || size" @click='item.handle()'>{{item.label}}</el-button>
        </el-form-item>
    </el-form>

    <el-dialog append-to-body :visible.sync="dialogVisible" :title="$t('tips.select')">
      <select-image
          :multiple="false"
          :active="false"
          @select="getImages"
          @close="dialogVisible = false">
      </select-image>
    </el-dialog>

    <!-- <el-form inline v-if='isHandle'>
        <el-form-item v-for='item in createHandle' :key="item.label">
            <el-button :type="item.type" :size="item.size || size" @click='item.handle()'>{{item.label}}</el-button>
        </el-form-item>
    </el-form> -->
  </div>
</template>
<script>
import selectImage from '@/components/Images';

export default {
    name: 'create-form',
    props:{
        inline: {
            type: Boolean,
            default: false
        },
        isHandle:{
            type:Boolean,
            default:true
        },
        labelWidth:{
            type:String,
            default:'80px'
        },
        size:{
            type:String,
            default:'medium'
        },
        createForm:{
            type:Array,
            default:[]
        },
        createHandle:{
            type:Array,
            default:()=>[]
        },
        createData:{
            type:Object,
            default:{}
        },
        rule:{
            type:Object,
            default:()=>[]
        }
    },
    components: {
      selectImage
    },
    data () {
        return {
          dialogVisible: false,
          type: ''
        };
    },
    methods: {
        validate() {
            return new Promise(resolve => {
                this.$refs.createRef.validate(resolve)
            })
        },
        clearValidate() {
            this.$refs.createRef.clearValidate()
        },
        bindEvent() {
            console.log('b')
            // const _this = this
            // this.$nextTick(() => {
            //     let cascaderDom = document.getElementsByClassName(_this.refName)[0]
            //     if (cascaderDom) {
            //     setTimeout(() => {
            //         let labelDoms = cascaderDom.querySelectorAll('.el-cascader-node .el-cascader-node__label')
            //         let radioDoms = cascaderDom.querySelectorAll('.el-cascader-node .el-radio__inner')
            //         if (labelDoms && labelDoms.length) {
            //         labelDoms.forEach((item, index) => {
            //             item.addEventListener('click', () => {
            //             radioDoms[index].click()
            //             })
            //         })
            //         labelDoms.forEach((item) => {
            //             item.addEventListener('dblclick', () => {
            //             _this.$refs.elCascader.dropDownVisible = false
            //             })
            //         })
            //         }
            //     }, 500)
            //     }
            // })
            this.$refs.cascader.dropDownVisible = false
        },

        mediaUpload(type,index = 0) {
          this.dialogVisible = true
          this.index = index
          this.type = type
        },
        getImages(data) {
          this.createData[this.type] = data
          this.dialogVisible = false
        },
    },
    watch: {
        // 监听语言切换(清除验证)
        '$i18n.locale' : function () {
            this.$nextTick(() => {
                this.clearValidate()
            });
        },
    },
}
</script>

<style>
.selectImage{
  z-index: 9999 !important;
}
</style>